var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account"},[_c('div',{staticClass:"account-title"}),_c('div',[_c('yl-portlet',{attrs:{"title":"","titleText":"受试者上报数据"}},[_c('div',{staticClass:"patient-list"},[_c('div',{staticStyle:{"width":"85px"}},[_vm._v("当前中心 ")]),_c('yl-select',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请选择"},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},_vm._l((_vm.options),function(item){return _c('yl-option',{key:item.id,attrs:{"value":item.key,"label":item.name}})}),1)],1),_c('div',[_c('div',{staticClass:"second-title"},[_vm._v("中心列表")]),_c('yl-table',{attrs:{"columns":_vm.tableHeader,"data":_vm.tableData,"empty-text":"没有数据哦"},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s((((_vm.page.currentPage-1)*_vm.page.pageSize))+row._index)+" ")])]}},{key:"url",fn:function(ref){
var row = ref.row;
return [_c('a',[_vm._v(_vm._s(row.url))]),_c('el-button',{ref:"copyButton",staticClass:"ky-btn-icon",attrs:{"size":"small","type":"text","data-clipboard-text":"Just because you can doesn't mean you should — clipboard.js"},on:{"click":function (e) { return _vm.clipboard(e, row); }}},[_vm._v(" 复制 ")])]}},{key:"operation",fn:function(ref){
var row = ref.row;
var column = ref.column;
var index = ref.index;
return [_c('el-button',{staticClass:"ky-btn-icon",attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.seeQR(row,column,index)}}},[_vm._v(" 查看 ")]),_c('el-button',{staticClass:"ky-btn-icon",attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.downloadQR(row,column,index)}}},[_vm._v(" 下载 "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDownloadQR),expression:"showDownloadQR"}],staticClass:"qrCode download"},[_c('canvas',{attrs:{"id":"canvas-download"}})])])]}}])}),(_vm.total > 10)?_c('yl-pagination',{attrs:{"total":_vm.total,"currentPage":_vm.page.currentPage},on:{"changeCurrentPage":_vm.changePage,"changeSize":_vm.changeSize}}):_vm._e()],1)])],1),_c('el-dialog',{attrs:{"visible":_vm.showQR,"title":"随访二维码"},on:{"update:visible":function($event){_vm.showQR=$event}}},[_c('div',[_vm._v(_vm._s(("中心：" + (_vm.selectedCenter.centerName))))]),_c('div',{staticClass:"qrCode"},[_c('canvas',{attrs:{"id":"canvas"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }