












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BreadCrumbs extends Vue {
  private crumbsList: any = [];

  mounted () {
    // console.log(this.$route.matched)
    const list = this.$route.matched
    list.shift()
    this.crumbsList = list
    console.log(this.crumbsList)
  }
}
