import request from '@/api/index'

// 获取中心列表
export function getCenterList (params: object): any {
  return request({
    url: '/web/user/center/list/',
    method: 'GET',
    params
  })
}
// 获取受试者统计信息
export function getStatistics (params: object): any {
  return request({
    url: '/web/index/patient/statistics/',
    method: 'GET',
    params
  })
}
// 获得逾期问卷
export function getOverdue (params: object): any {
  return request({
    url: '/web/index/questionnaire/list/',
    method: 'GET',
    params
  })
}

// 获取中心二维码列表
export function getCenterQRList (params: object): any {
  return request({
    url: '/web/user/center/qr-code/',
    method: 'GET',
    params
  })
}
