import { Message } from 'element-ui'
import Vue from 'vue'

/**
 *  @name messageBox
 * @param {'success' | 'info' | 'warning' | 'error' | 'loading' } type
 * @param {string} msg
 * @description 全局弹窗
 */
export function messageBox (type:'success' | 'info' | 'warning' | 'error' | 'loading', msg:string) {
  const notifyPromise = Promise.resolve()
  notifyPromise.then(Vue.nextTick).then(() => {
    Message[type](msg)
    // const hide = message.loading(msg)
    // hide()
  })
}
