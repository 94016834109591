

































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import Cookie from 'js-cookie'
import { getCenterList, getCenterQRList } from '@/api/modules/project'
import { getPatientQuestionData } from '@/api/modules/patient'
import { getSessionStorage } from '@/utils/storage'
import handleClipboard from '@/utils/clipboard'
import QRCode from 'qrcode'

@Component({
  components: {
    BreadCrumbs
  }
})
export default class extends Vue {
  private select: string = Cookie.get('centerName') || '';
  private options: any = [];
  private patientNumber: string = '';
  private tableHeader: object = [
    { text: '序号', slot: 'index' },
    { text: '中心编号', field: 'centerNumber' },
    { text: '中心名称', field: 'centerName' },
    { text: '随访链接', slot: 'url' },
    { text: '随访二维码', slot: 'operation' }
  ];
  private tableData: object = [];
  private page: any = {
    currentPage: 1,
    pageSize: 10
  };
  private total: number = 0;
  private loading = true;
  private showQR: boolean = false
  private showDownloadQR: boolean = false
  private selectedCenter: any = {}

  get projectName (): number {
    return getSessionStorage('projectName')
  }
  created () {
    // 中心列表
    this.getCenter()
    // 获得上报数据
    this.getCenterQRData()
  }
  @Watch('select', { deep: true })
  OnchangeSelect (newVal: any) {
    this.page.currentPage = 1
    this.page.pageSize = 10
    if (this.options.length === 0) {
      return
    }
    Cookie.set('centerName', Number(newVal))
    this.getCenterQRData()
  }

  clipboard (event: any, row:any) {
    console.log(row)
    handleClipboard(row.url, event)
  }
  // 分页
  private changePage (value: number): void {
    this.page.currentPage = value
    this.getCenterQRData()
  }
  private changeSize (value: number): void {
    this.page.pageSize = value
    this.getCenterQRData()
  }
  // 获得中心列表
  private getCenter () {
    let params = {
      userId: Cookie.get('id'),
      projectId: Cookie.get('projectId')
    }
    getCenterList(params).then((res: any) => {
      res.data.list.forEach((element: { key: any; name: any }) => {
        console.log(element)
        element.key = element.name
      })
      if (res.data.list.length > 0) {
        this.options = [{ id: 0, name: '全部', code: '', key: '' }, ...res.data.list]
      }
      this.select = Cookie.get('centerName') || ''
    })
  }
  // 获得上报数据
  private getCenterQRData () {
    let data = {
      userId: Cookie.get('id'),
      projectId: Cookie.get('projectId'),
      keyword: this.select
    }
    getCenterQRList(data).then((res: any) => {
      this.tableData = res.data.list.map((item: any) => {
        return {
          ...item,
          createTime: this.timestampToTime(item.createTime)
        }
      })
      this.total = res.data.total
    })
  }
  private seeQR (row: any, column: any, index: number) {
    this.selectedCenter = row
    this.showQR = true
    this.$nextTick(() => {
      console.log(row)
      this.useqrcode(row.url, 'canvas')
    })
  }

  private downloadQR (row: any, column: any, index: number) {
    this.selectedCenter = row
    this.showDownloadQR = true
    this.$nextTick(() => {
      this.useqrcode(row.url, 'canvas-download')
      this.drawImg(row)
      this.showDownloadQR = false
    })
  }

  useqrcode (url: string, id: string) {
    const canvas = document.getElementById(id)
    QRCode.toCanvas(canvas, url, function (error) {
      if (error) console.error(error)
      console.log('QRCode success!')
    })
  }

  drawImg (row: any) { // 定义一个方法，来绘制我们要导出的二维码
    console.log('drawImg == ')
    let ah = document.createElement('a')
    let cas:any = document.getElementById('canvas')
    var data1 = cas.toDataURL('image/jpg')
    var img = new Image() // 新建一个图片元素
    img.src = data1
    ah.href = img.src
    ah.download = row.centerName
    ah.click()
  }

  // base64转blob
  base64ToBlob (code: any) {
    let parts = code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: contentType })
  }

  private search () {
    this.page.currentPage = 1
    this.page.pageSize = 10
    this.getCenterQRData()
    this.getCenterQRData()
  }
  private clean () {
    this.patientNumber = ''
    this.page.currentPage = 1
    this.page.pageSize = 10
    this.getCenterQRData()
  }
  private timestampToTime (timestamp: any) {
    var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '年'
    var M =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '月'
    var D =
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日 '
    var h =
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m =
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':'
    var s =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + h + m + s
  }
}
