import Clipboard from 'clipboard'
import { messageBox } from './mesaage'

function clipboardSuccess () {
  messageBox('success', '复制成功')
}

function clipboardError () {
  messageBox('error', '复制失败')
}

export default function handleClipboard (text:string, event:Event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
